<template>
  <div>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader>
            <strong>Acknowledgement</strong>
          </CCardHeader>
         <CRow>
         <CCol md="8"> 

          <CCardBody>
            <CForm>
           
              <CInput
                label="Technician "
                v-model="briefing.firstName"
                horizontal
               
                :disabled="mode.viewMode"
                 :invalid-feedback="'Please Enter the Topic'"
                   
                
             />

          
              <div class="form-group row">
						 <label for="example-text-input text-right" class="col-sm-3 col-form-label"> Acknowledged Status </label>
						 <div class="col-sm-9">
						
							<select class="form-control"  v-model="briefing.isAcknowledged" :disabled="mode.viewMode" >
										  <option disabled value="">--Select One Option--</option>
										  <option value="0">Not Acknowledged</option>
                      <option value="1">Acknowledged</option>
										  <option value="2">Not Acknowledged</option>
                        <option value="3">Attending</option>
								 </select>
								 </div>
						   </div>



             <CInput
                label="Reason "
                v-model="briefing.reasonIfNotAck"
                horizontal
               
                :disabled="mode.viewMode"
                 :invalid-feedback="'Please Enter the Date'"
                   
                   
              />

            </CForm>
            
          </CCardBody>
          </CCol>
          
          </CRow>
          
          <CCardFooter align="right">
         <!-- <CButton
              v-if="mode.viewMode"
              class="mr-2"
              type="submit"
              size="sm"
              color="primary"
              @click="editMode()"
            >
              <CIcon name="cil-pencil" />Edit
            </CButton> -->

            <!-- <CButton
              v-if="!mode.viewMode"
              class="mr-2"
              type="submit"
              size="sm"
              color="success"
              @click="saveSpeciality()"
            >
              <CIcon name="cil-check-circle" />Save
            </CButton> -->

            <CButton class="mr-2" type="Cancel" size="sm" color="danger"  @click="cancel()">
              <CIcon name="cil-ban" /> Cancel
            </CButton>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
 import Vue from "vue";
 import datetime from 'vuejs-datetimepicker';
import {
  required,
  minLength,
  maxLength,
  url,
  requiredIf,
} from "vuelidate/lib/validators";
export default {
  name: "Acknowledgement",
  data() {
    return {
      mode:{
        newMode:false,
        editMode:false,
        viewMode:false,
      },
     acknowledgeId:"",
      briefing:{
       acknowledgeId:"0",
        briefingId:'',
       firstName:'',
       isAcknowledged:'',
       reasonIfNotAck:'',
      },
   
      horizontal: { label: "col-3", input: "col-9" },
    
      
    //   selectedOption: "some value",

    //   formCollapsed: true,
    //   checkboxNames: [
    //     "Checkboxes",
    //     "Inline Checkboxes",
    //     "Checkboxes - custom",
    //     "Inline Checkboxes - custom",
    //   ],
     
    };
 },

 validations: {
   briefing: {
      
  //     title: {
  //          required,
       
  //     },
  //  date: {
  //          required,
       
  //     },
  //     time: {
  //          required,
       
  //     },
  //      userType:{
  //          required,
       
  //     },
    },
  },
   created() {  
             if (this.$route.params.mode == "new") {
                      this.mode.newMode = true;
                        this.mode.viewMode = false;
                        this.mode.editMode = false;
                 }
             if(this.$route.params.mode =='view'){
                   this.mode.viewMode=true;
                    this.acknowledgeId = this.$route.params.acknowledgeId;
                     this.briefingId = this.$route.params.briefingId;
                    this.getUser();
            }
            if (this.$route.params.mode == "edit") {
                this.mode.editMode = true;
                this.acknowledgeId= this.$route.params.acknowledgeId;
                 this.getUser();
    }
        },
         components: { datetime },
  methods: {
    editMode(){
       this.mode.editMode=true;
        this.mode.viewMode=false;
    },
     cancel() {
      // this.$router.push({ name: 'Acknowledgements' })
        this.$router.push({ name: "Acknowledgements", params: {briefingId: this.$route.params.briefingId } });
    },
    // saveSpeciality() {
    //   this.$v.briefing.$touch();
    //   if (this.$v.briefing.$invalid) {
    //     console.log("form is invalid", JSON.stringify(this.$v.briefing));
    //   }else{
    //  this.apiPostSecure(process.env.VUE_APP_API_HOST + "/web/faq/save", this.briefing
    //   ).then((post) => {
    //       this.$router.push({ name: "Briefing" });
    //     });
    //   }
     
    // },
    validator(val) {
      return val ? val.length >= 4 : false;
    },
    
    
    getUser() {
     this.apiGetSecure(process.env.VUE_APP_API_HOST +"/emapp/secure/web/secure/get/acknowledge/"+this.acknowledgeId)
        .then((response) => response.json())
        .then( (data)  => (this.briefing = data));
    },
    
    // getRoles() {
    // fetch(process.env.VUE_APP_API_HOST +"/web/secure/meta/roles")
    //     .then((response) => response.json())
    //     .then( (data)  => (this.roles = data) );
    // },
   
  },
  mounted() {
    //this. getRoles();
   
  }
};
</script>
